export const AUTHENTICATION_KEY = '__storejs_bdm_token';
export const STATS_MODULE_PERMISSION = 'accessStatsModule';

export const USER_DATA_SEARCH_ENDPOINT = 'attributes/person_filtered';

export const Filters = {
    ORGANIZATION: 'organization',
    USER_DATA_SECTION: 'user_data_section',
    OFFICER: 'officer',
    POLICY_ALERT: 'policy_alert',
    ASSIGNMENT: 'assignment',
    TIME_PERIOD: 'timePeriod',
    CHILD_ORGANIZATIONS: 'child_organizations',
    DEMOGRAPHIC: 'demographic',
    PAGINATION: 'pagination',
    SORTING: 'sorting',
    GENERIC_TABLE_SEARCH: 'generic_table_search',
    PEER_AND_ALL_DEMOGRAPHICS: 'peer_and_all_demographics',
    PEER_AND_DEMOGRAPHIC_TYPE: 'peer_and_demographic_type',
    PEER: 'peer',
    EMPLOYEE: 'employee',
    ADHOC: 'adhoc',
    DOWNLOAD_PDF: 'download_pdf',
    SUMMARY_STATS_CARD: 'summary_stats_card',
    GENERIC_RADIO_BUTTON: 'generic_radio_button',
    INVESTIGATOR: 'investigator',
    FEATURE: 'feature'
};

export const FilterPreferences = {
    [Filters.GENERIC_TABLE_SEARCH]: {
        isTemporary: true
    },
    [Filters.EMPLOYEE]: {
        isTemporary: true
    }
};

export const DateUnit = {
    MONTH: 'month',
    QUARTER: 'quarter',
    YEAR: 'year',
    DAY: 'day'
};

export const ALL_FIELDS = 'ALL_FIELDS';

export const RELEVANT_FILTER_KEYS = {
    [Filters.ORGANIZATION]: ['organization_id'],
    [Filters.USER_DATA_SECTION]: ['user_data_section_id'],
    [Filters.OFFICER]: ['person_filter'],
    [Filters.POLICY_ALERT]: ['policy_alert'],
    [Filters.ASSIGNMENT]: ['assignment_id'],
    [Filters.TIME_PERIOD]: ['force_date_id', 'date_id', 'date_unit', 'timespan_units'],
    [Filters.CHILD_ORGANIZATIONS]: ['include_child_organizations'],
    [Filters.DEMOGRAPHIC]: ['type', 'dimension'],
    [Filters.PAGINATION]: ['page', 'page_size'],
    [Filters.SORTING]: ['order', 'field'],
    [Filters.PEER_AND_ALL_DEMOGRAPHICS]: ['type', 'dimension'],
    [Filters.PEER_AND_DEMOGRAPHIC_TYPE]: ['type'],
    [Filters.EMPLOYEE]: ['person_id'],
    [Filters.GENERIC_TABLE_SEARCH]: ALL_FIELDS,
    [Filters.SUMMARY_STATS_CARD]: ['stats_navigation_filter'],
    [Filters.GENERIC_RADIO_BUTTON]: ALL_FIELDS,
    [Filters.INVESTIGATOR]: ['investigator_id'],
    [Filters.FEATURE]: ['feature_slug']
};

export const PAGINATION_CONFIGURATION = {
    defaultPageSize: 10,
    pageSizeOptions: ['10', '25', '50'],
    showSizeChanger: true,
    locale: { items_per_page: '' }
};

export const RED_COLOR_GROUP = {
    primary: '#C1001B',
    secondary: '#FFEDEC',
    text: '#C1001B'
};

export const ORANGE_COLOR_GROUP = {
    primary: '#F67814',
    secondary: '#FFF5E0',
    text: '#F67814'
};

export const GREEN_COLOR_GROUP = {
    primary: '#518F00',
    secondary: '#FAFFDD',
    text: '#518F00'
};

export const GREY_COLOR_GROUP = {
    primary: '#D0D0D0',
    secondary: '#F2F2F2',
    text: '#474747'
};

export const BLUE_COLOR_GROUP = {
    primary: '#0000FF',
    secondary: '#ADD8E6',
    text: '#0000FF'
};
export const PALE_BLUE_COLOR_GROUP = {
    primary: '#79A4CA',
    secondary: '#ADD8E6',
    text: '#0000FF'
};
export const INDIGO_COLOR_GROUP = {
    primary: '#6971B0',
    secondary: '#bdc4fc',
    text: '#3030DD'
};
export const TEAL_COLOR_GROUP = {
    primary: '#8ECAD4',
    secondary: '#c8dfe3',
    text: '#029ab5'
};
export const PALE_GREEN_COLOR_GROUP = {
    primary: '#C6E6CD',
    secondary: '#e8fced',
    text: '#00BB00'
};
export const YELLOW_COLOR_GROUP = {
    primary: '#FFFFD1',
    secondary: '#fcfceb',
    text: '#CCCC00'
};

export const COLOR_FUNCTIONS = {
    traffic_light: (indicator) => {
        switch (indicator) {
            case 'severe':
                return RED_COLOR_GROUP;
            case 'warning':
                return ORANGE_COLOR_GROUP;
            case 'good':
                return GREEN_COLOR_GROUP;
            case 'boring':
                return GREY_COLOR_GROUP;
            case 'standard':
                return BLUE_COLOR_GROUP;
            default:
                return {};
        }
    },
    internal_affairs: (indicator) => {
        switch (indicator) {
            case 'OPEN':
                return RED_COLOR_GROUP;
            case 'IN_REVIEW':
                return ORANGE_COLOR_GROUP;
            case 'COMPLETED':
                return GREY_COLOR_GROUP;
            default:
                return {};
        }
    },
    excellence: (indicator) => {
        switch (indicator) {
            case 'excellence-high':
                return INDIGO_COLOR_GROUP;
            case 'excellence-elevated':
                return PALE_BLUE_COLOR_GROUP;
            case 'excellence-expected':
                return TEAL_COLOR_GROUP;
            case 'excellence-depressed':
                return PALE_GREEN_COLOR_GROUP;
            case 'excellence-low':
                return YELLOW_COLOR_GROUP;
            default:
                return {};
        }
    },
    plain: (indicator) => {
        return {
            primary: '#D0D0D0',
            secondary: '#F9F9F9',
            text: '#1C1C1C'
        };
    }
};

export const MAIN_CHART_COLORS = [
    '#025DF4',
    '#DB6BCF',
    '#2498D1',
    '#4045B2',
    '#FF745A',
    '#946DFF',
    '#626681',
    '#EB4185',
    '#36BCCB',
    '#327039',
    '#4a3270'
];

export const ALT_CHART_COLORS = [
    '#9ABEFB',
    '#F1C4EC',
    '#D3EEF9',
    '#BBBDE6',
    '#FFC7BD',
    '#D3CEFD',
    '#CED4DE',
    '#F7B3CE',
    '#8CDAE5',
    '#83bc99',
    '#9583b1'
];

export const MAX_COL_SPAN = 12;

export const TIME_PERIOD_FILTER_FORMAT = 'YYYYMMDD';

export const MAX_ADHOC_RESULT_COUNT = 20;

export const ADHOC_MAX_CHARS_FILENAME_PREFIX = 3;

export const ADHOC_SAFE_CHAR_REPLACEMENT_FILENAME_PREFIX = '_';

export const ADHOC_MODULE_NAME = 'ad_hoc';

export const USER_DATA_MODULE_NAME = 'user_data';

export const IS_ACTIVE_FIELD_NAME = 'is_deleted';

export const FIRST_SIGN_OFFICER_ADVOCACY_SUITE_PATH = 'first_sign_officer_advocacy_suite';
