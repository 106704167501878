import { Button } from 'antd';
import { FiltersActions, FiltersContext } from '../../../contexts/FiltersContext';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'antd';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { PDFProgressContext } from '../../../contexts/PDFProgressContext';
import { DownloadOutlined } from '@ant-design/icons';
pdfMake.vfs = pdfFonts;
console.log('vfs is: ', pdfFonts)

const DownloadPDFFilter = ({ title }) => {
    const { progressCount, progressIncrement, progressReset } = useContext(PDFProgressContext)
    const [{ pdfDownload }, dispatch] = useContext(FiltersContext);
    const {
        loading: pdfDownloadLoading,
        error: pdfDownloadError,
        title: pdfTitle,
        pdf,
        reportDate,
        complete: pdfComplete
    } = pdfDownload;

    const handleDownload = () => {
        progressReset()
        dispatch({
            type: FiltersActions.START_PDF_DOWNLOAD
        });
    };

    const handleOk = () => { };

    useEffect(() => {
        if (pdf && pdfComplete) {
            pdfMake.createPdf(pdf).download(`${pdfTitle}-${reportDate}.pdf`);
            dispatch({
                type: FiltersActions.CANCEL_PDF_DOWNLOAD
            });
        }
        if (pdfDownloadError) {
            Modal.warning({
                title: 'PDF Download ERROR',
                content: pdfDownloadError,
                onOk: handleOk
            });
            dispatch({
                type: FiltersActions.CANCEL_PDF_DOWNLOAD
            });
        }
    }, [pdfDownloadLoading, pdfDownloadError, pdfComplete]);

    return (
        <div className="flex flex-row flex-grow justify-end">
            <div className="flex-col justify-end items-end ">
                <Modal visible={pdfDownloadLoading} title="Downloading" closable={false} footer={null}>
                    <p>Your PDF is being created and will be available soon.</p>
                    <p>Please wait ...</p>
                    <p>{progressCount} Components Created</p>
                </Modal>
                <div className="p-2 lg:pl-6">
                    <h3 className="text-xs font-extralight pb-1 text-black text-opacity-80">&nbsp;</h3>
                    <Button
                        type="primary"
                        shape="round"
                        size="large"
                        icon={<DownloadOutlined />}
                        onClick={handleDownload}>
                        Download PDF
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DownloadPDFFilter;
