import { useMemo, useState, useCallback, useEffect } from 'react';
//import { Cell, ResponsiveContainer, Legend } from 'recharts';
import { useCurrentPng } from 'recharts-to-png';
import { MAIN_CHART_COLORS } from '../../constants';
import LoadingSpinner from '../states/LoadingSpinner';
import ErrorState from '../states/ErrorState';
import EmptyState from '../states/EmptyState';
import { returnSortedData, transformDataIndexField } from '../../utilities';
import { PolarArea } from 'react-chartjs-2';
import { ArcElement, Chart, Legend, Title, Tooltip, RadialLinearScale } from 'chart.js';
import { COLOR_FUNCTIONS } from '../../constants/index';

import { splitWords, breakWords, transparentize } from '../../utilities';

Chart.register(ArcElement, Title, Legend, Tooltip, RadialLinearScale);

const PolarAreaChartComponent = ({ data, loading, error, params, endpoint, pdfDelegate }) => {
    const {
        name,
        valueField,
        labelField,
        colorField,
        axisLabelField,
        height = 250,
        isAnimationActive = true,
        includeAxisLabel = false,
        includeLegend = false,
        includeScale = true,
        axisLabelBreakWord = true,
        startAngle = -73,
        scaleMin = -1,
        scaleMax = 4,
        stepSize = 1,
        circular = false,
        labelFontSize = 18,
        colorFunction = 'excellence',
        colorTransparent = 0.25,
        borderColor = '#fff'
    } = params;

    const validatedData = useMemo(() => {
        if (data) {
            const hasValidDataPoints = !!data.filter((item) => !!item[valueField]).length;
            return hasValidDataPoints ? data : [];
        }
        return null;
    }, [data]);

    const axisLabelFormatter = (string = '') => {
        let formattedString = string;
        if (axisLabelBreakWord) {
            formattedString = breakWords(formattedString);
        }

        return formattedString;
    };

    const chartData = validatedData ? validatedData.map((x) => x[valueField] || 0) : [];
    const chartLabels = validatedData ? validatedData.map((x) => x[labelField] || 0) : [];
    const chartAxisLabels = validatedData ? validatedData.map((x) => axisLabelFormatter(x[axisLabelField]) || 0) : [];
    let chartColors = validatedData ? validatedData.map((x) => x[colorField] || 0) : [];
    chartColors = colorFunction ? chartColors.map((x) => COLOR_FUNCTIONS[colorFunction](x)['primary']) : chartColors;
    chartColors = colorTransparent ? chartColors.map((x) => transparentize(x, colorTransparent)) : chartColors;

    return (
        <PolarArea
            options={{
                circular: circular,
                borderColor: borderColor,
                borderWidth: 1,
                plugins: {
                    title: {
                        display: true,
                        text: name
                    },
                    legend: {
                        display: includeLegend,
                        position: 'bottom'
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let index = context.dataIndex;
                                let dataLabel = context.dataset.dataLabel[index] || '';
                                let axisLabel = context.label || '';
                                axisLabel = Array.isArray(axisLabel) ? axisLabel.join(' ') : axisLabel;
                                let label = axisLabel ? axisLabel + ': ' + dataLabel : dataLabel;
                                return label;
                            }
                        }
                    }
                },
                scales: {
                    r: {
                        display: includeScale,
                        startAngle: startAngle,
                        min: scaleMin,
                        max: scaleMax,
                        grid: { circular: circular },
                        pointLabels: {
                            display: true,
                            centerPointLabels: true,
                            font: { size: labelFontSize }
                        },
                        ticks: {
                            stepSize: stepSize,
                            display: includeAxisLabel
                        }
                    }
                }
            }}
            data={{
                labels: chartAxisLabels,
                datasets: [
                    {
                        label: 'Count',
                        data: chartData,
                        backgroundColor: chartColors,
                        dataLabel: chartLabels
                    }
                ]
            }}
        />
    );
};

export default PolarAreaChartComponent;
